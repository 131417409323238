<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <div class="mission-title">
      <h1 style="height: 50px;line-height: 50px;">웨이포인트 목록</h1>
      <a @click="handleCreate" :to="{ name: 'MissionCreate' }">등록하기</a>
    </div>
    <div class="liveList" style="height: calc(100% - 50px);">
      <div class="search-from">
        <h2>상세검색</h2>
        <div class="search-from-input">
          <h4>{{ $t("search-keyword-title") }}</h4>
          <div class="search-input">
            <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="title">{{ $t("mission-data-way-title") }}</option>
            <option value="departmentName">{{ $t("mission-data-way-dept") }}</option>
            <option value="userName">{{ $t("mission-data-way-registrant") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
          </div>
        </div>
        <div class="search-from-input">
          <h4>{{ $t("search-regdate-title") }}</h4>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <Datepicker
              v-model="endYmd" 
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="search-from-btn">
          <button class="point medium" @click="search"  style="width: 49%;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 49%;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-reset") }}
          </button>
        </div>
      </div>
      <div class="tabBox one" style="width: 22%;height: calc(100% - 291px);margin-right: 20px; margin-bottom: 0px;">
        <div class="wrap-content" style="height: 100%;">
          <div class="wrap-info">
            <h2>웨어포인트 목록</h2>
            <h4>
              {{ $t("data-total") }} <span>{{ total }}</span
            >{{ $t("data-case-ea") }}
              <!-- [{{ currentPage + 1 + "/" + Math.ceil(total / size) }}] -->
            </h4>
          </div>
          <div class="wrap-list">
            <div class="wrap-list-none"  v-if="total == 0">
              {{ $t("search-not-found") }}
            </div>
            <div class="wrap-list-box"
                  v-for="(data, i) in missionList"
                  :key="i"
                  @click="handleSelectEl(data)"
                  :class="{ active: selectEl != null && selectEl.missionId == data.missionId }">
              <div class="wrap-list-box-header">
                <div class="wrap-list-title">
                  <h2>{{ data.title }}</h2>
                  <h4>{{ dateFormat(data.registerDate) }}</h4>
                </div>
                <div class="wrap-list-number">
                  <span v-if="false" class="st-mark">스테이션</span>
                  <span>{{ total - (size * currentPage + i) }}</span>
                </div>
              </div>
              <ul>
                <li>
                  <span>{{ $t("mission-data-way-registrant") }}</span>
                  <h4>{{ data.userName }}</h4>
                </li>
                <li>
                  <span>{{ $t("mission-data-way-dept") }}</span>
                  <h4>{{ data.userDepartmentName }}</h4>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination" style="padding-top: 15px;">
            <el-pagination
                small
                layout="prev, pager, next"
                :total="total"
                :page-size="size"
                @current-change="handleCurrentChange"
                :current-page="currentPage + 1"
            >
            </el-pagination>
          </div>
          <!-- <div class="tableWrap" style="margin-left: 16px;">
            <table>
              <tr>
                <th>No</th>
                <th>{{ $t("mission-data-way-title") }}</th>
                <th>{{ $t("mission-data-way-registrant") }}</th>
                <th>{{ $t("mission-data-way-dept") }}</th>
                <th>{{ $t("mission-data-way-regdate") }}</th>
              </tr>
              <tr v-if="total == 0">
                <td colspan="5" style="text-align: center;">
                  {{ $t("search-not-found") }}
                </td>
              </tr>
              <tr
                  v-for="(data, i) in missionList"
                  :key="i"
                  @click="handleSelectEl(data)"
                  :class="{ active: selectEl != null && selectEl.waypointId == data.waypointId }"
              >
                <td>{{ total - (size * currentPage + i) }}</td>
                <td>{{ data.title }}</td>
                <td>{{ data.userName }}</td>
                <td>{{ data.userDepartmentName }}</td>
                <td>{{ dateFormat(data.registerDate) }}</td>
              </tr>
            </table>
            <div class="pagination">
              <el-pagination
                  small
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage + 1"
              >
              </el-pagination>
            </div>
          </div> -->
        </div>
      </div>
      <div class="map" style="width: calc(78% - 45px);height: calc(100% + 0px);position: absolute;right: 0;bottom: 0" >
        <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" />
        <button v-show="this.selectEl != null" class="point medium" style="width:150px; position: absolute; left: 45%; bottom: 20px;background: #0080FF;border: 1px solid #0080FF;border-radius: 5px;"  @click="handleDetail">
          <a href="javascript:void(0);">{{ $t("btn-detail") }}</a>
        </button>
      </div>
    </div>
  </div>

</template>

<style>
.title{display: none;}
.search-from{width: 22%;background: #fff;border-radius: 5px;padding: 15px;margin-bottom: 15px;}
.search-from > h2{font-size: 1.4rem;font-weight: bold;border-bottom: 1px solid #ddd;padding-bottom: 5px;margin-bottom: 5px;}

.search-from-input{display: flex;flex-direction: column;width: 100%;margin-bottom: 5px;}
.search-from-input > h4{font-size: 1.2rem;font-weight: 400;color: #969696;margin-bottom: 5px;}
.search-from-input > .search-input{display: flex;margin-bottom: 5px;}
.search-from-input > .search-input > select{width: 30%;border-radius: 5px;margin-right: 5px;}
.search-from-input > .search-input > input{width: 69%;border-radius: 5px;margin-bottom: 0px;}
.search-from-input > .vdp-datepicker{width: 100%;}
.search-from-input > .vdp-datepicker input{width: 100%;border-radius: 5px;}
.search-from-btn{display: flex;flex-direction: row;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;}

.wrap-info{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.wrap-info > h2{font-size: 1.4rem;font-weight: bold;margin: 0;}
.wrap-info > h4{font-size: 1.2rem;font-weight: 500;}
.wrap-info > h4 > span{color: #0080FF;font-weight: bold;font-size: 1.2rem;}

.wrap-list{height: calc(95% - 50px);overflow-y: scroll;}
.wrap-list-none{font-size: 1.6rem;text-align: center;padding-top: 20rem;}
.wrap-list-box{border: 1px solid #ddd;margin-bottom: 10px;border-radius: 5px;padding: 10px;}
.wrap-list-box-header{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding-bottom: 10px;}
.wrap-list-title > h2{font-size: 1.4rem; font-weight: bold;margin-bottom: 5px;}
.wrap-list-title > h4{font-size: 1.2rem;font-weight: 400;color: #969696;}
.wrap-list-number{display: flex;justify-content: space-between;}
.wrap-list-number > .st-mark{width: auto;padding: 4px 10px;margin-right: 5px;background: #E5F0FF;color: #0080ff;}
.wrap-list-number > span{width: 25px;height: 25px;background: #0080FF;border-radius: 50px;text-align: center;color: #fff;display: block;padding-top: 5px;font-size: 1.2rem;}

.wrap-list-box:hover{border: 1px solid #0080FF;cursor: pointer;}
.wrap-list-box.active{border: 1px solid #0080FF;background: #f7fbff;}
.wrap-list-box.active > .wrap-list-box-header > .wrap-list-title > h2{color: #0080FF;}

.wrap-list-box > ul{padding-top: 10px;}
.wrap-list-box > ul > li{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.wrap-list-box > ul > li:nth-child(2){margin-bottom: 0;}
.wrap-list-box > ul > li > span{width: 75px;background: #eee;text-align: center;border-radius: 10px;color: #969696;font-size: 1.2rem;}
.wrap-list-box > ul > li > h4{font-size: 1.2rem;font-weight: 500;}
.missionActive{background: #0080FF;}
.mission-sub > li:nth-child(2) > a{background: #0080FF;border-radius: 10px;}

.mission-title{width: 22%;display: flex;align-items: center;justify-content: space-between; margin-top: 50px;}
.mission-title > a{background: #fff;border: 1px solid #0080FF;color: #0080FF;padding: 5px 20px;border-radius: 5px;font-weight: 400;font-size: 1.2rem;}
</style>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchWaypointList } from "@/api/missionWaypoint";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";

import { fetchEvent } from "@/api/event";
import { fetchWaypointLocationList} from "@/api/missionWaypointLocation";
import { fetchMissionLocationList} from "@/api/missionLocation";
import {fetchMissionList} from "@/api/mission";

import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
fetchWaypointList
fetchWaypointLocationList
export default {
  components: { Datepicker, Map },
  name: "MissionList",
  props: ["eventId"],
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      missionList: [],
      visible: 1,
      total: 0,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
      loadMap : 'kakao',
      selectEl : null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 9 });
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    this.getMissionList();
    this.getEvent();
  },
  methods: {
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.onMapCenter();
        this.setMapSetting();
        if(this.selectEl != null) {
          this.getWaypointLocationList();
        }
      })
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    setMapSetting(){
      this.$refs.map.onShowButtonTypeChange();
    },
    handleSelectEl(data){
      this.$refs.map.onClearMapData();
      if(this.selectEl != null && this.selectEl.missionId == data.missionId) {
        this.selectEl = null;
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat);
        return;
      }
      this.selectEl = data;
      this.getWaypointLocationList();
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    getWaypointLocationList(){
      let params = {
        eventId : this.eventId,
        missionId : this.selectEl.missionId
      };
      fetchMissionLocationList
      fetchMissionLocationList(params).then((res) => {
        if (res.data.result == 0) {
          this.waypointLocationTotal = res.data.data.total;
          this.waypointLocationList = res.data.data.content;

          let sort = this.waypointLocationList.sort((a,b) => {return a.index - b.index})
          let mapDataType = MapDataType.ID_WAYPOINT
          if( sort.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

            sort.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            })
            this.createMapLine("way-point", sort);
          } else {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_WAYPOINT
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleDetail() {
      //var id = this.selectEl.waypointId;
      this.$router.push({ name: "MissionUpdate" , query: {eventId : this.eventInfo.eventId, missionId: this.selectEl.missionId }})
      //this.$emit("select-mission",id)
    },
    handleCreate() {
      //var id = this.selectEl.waypointId;
      this.$router.push({ name: "MissionCreate"})
      //this.$emit("select-mission",id)
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getMissionList();
      this.scrollToTop();
    },
    scrollToTop() {
      const wrapList = this.$el.querySelector('.wrap-list');
      if (wrapList) {
        wrapList.scrollTop = 0;
      }
    },
    search(){
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getMissionList();
    },
    reset(){
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    getMissionList() {
      var params = {
        eventId : this.eventId,
        refType : "None",
        type : "WayPoint",
        page_page: this.currentPage,
        page_size: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      
      // fetchWaypointList(params).then((res) => {
      //   this.total = res.data.data.totalElements;
      //   this.missionList = res.data.data.content;

      // });
      fetchMissionList(params).then((res) => {
        console.log("WayPoint")
        console.log(res)
        this.total = res.data.data.totalElements;
        this.missionList = res.data.data.content;
        console.log(res.data.data)
      });
    },
  },
};
</script>
